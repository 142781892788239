.download {
  background: #EEF1FF;
  overflow: hidden;
  padding: 120px 0 66px;

  @include xl {
    padding: 101px 0 70px;
  }

  @include md {
    padding: 105px 0 81px;
  }

  @include sm {
    padding: 81px 0;
  }

  @include xs {
    padding: 63px 0;
  }

  &--doc {
    color: $color-white;
    background: $color-darkBlue;
    padding: 121px 0 53px;

    .download__title {
      color: $color-white;
    }

    .download__info {
      max-width: 500px;

      @include xl {
        max-width: 400px;
      }

      @media (max-width: 830px) {
        max-width: 360px;
      }

      @include sm {
        max-width: 475px;
        margin: 0 auto 64px;
      }

      @include xs {
        margin: 0 auto;
      }
    }

    .download__subtitle {
      margin: 0 0 58px;

      @include xl {
        margin: 0 0 50px;
      }

      @include md {
        margin: 0 0 53px;
      }

      @include sm {
        margin: 0 0 41px;
      }
    }

    .download__img {
      margin: -54px 0 32px 19px;

      @include xl {
        align-self: flex-end;
        margin: -20px -154px 0 -83px;
      }

      @include md {
        margin: -24px -154px 0 -103px;
      }

      @include sm {
        margin: -42px -34px 0 -35px;
      }
    }
  }

  &--documents {
    @include xl {
      padding: 81px 0 38px;
    }

    @include md {
      padding: 121px 0 80px;
    }

    @include sm {
      padding: 60px 0 56px;
    }

    @include xs {
      padding: 41px 0;
    }

    .download__info {
      max-width: 513px;

      @include md {
        max-width: 490px;
      }

      @include sm {
        max-width: 360px;
        margin: 0 auto 59px;
      }

      @include xs {
        margin: 0 auto;
      }
    }

    .download__title {
      margin: 0 0 57px;

      @include md {
        margin: 0 0 46px;
      }
    }

    .download__img {
      margin: -78px 0 0 31px;

      @include xl {
        margin: -65px -190px 0 -164px;
      }

      @include md {
        margin: -53px -149px 0 -154px;
      }

      @include sm {
        margin: -24px -36px 0 -40px;
      }
    }

  }

  .container {
    display: flex;

    @include sm {
      display: block;
    }
  }

  &__info {
    max-width: 486px;
    width: 100%;

    @include md {
      max-width: 450px;
    }

    @media (max-width: 990px) {
      max-width: 420px;
    }

    @include sm {
      max-width: 390px;
      text-align: center;
      margin: 0 auto 50px;
    }

    @include xs {
      margin: 0 auto;
    }
  }

  &__title {
    color: $color-primary;
    margin: 0 0 24px;

    @include md {
      margin: 0 0 20px;
    }

    @include sm {
      margin: 0 0 12px;
    }

    @include xs {
      margin: 0 0 13px;
    }
  }

  &__subtitle {
    font-size: 18px;
    margin: 0 0 66px;

    @include xl {
      margin: 0 0 50px;
    }

    @include md {
      font-size: 16px;
      margin: 0 0 47px;
    }

    @include sm {
      margin: 0 0 40px;
    }

    @include xs {
      font-size: 14px;
      margin: 0 0 30px;

      br {
        display: none;
      }
    }
  }

  &__store {
    @include sm {
      justify-content: center;

      .store__wrap {
        max-width: 167px;
        margin: 0;

        img {
          width: 100%;
        }
      }

      .store__wrap a:not(:last-of-type) {
        margin: 0 0 27px;
      }
    }

    @include xs {
      .store__wrap {
        max-width: 137px;
      }

      .store__wrap a:not(:last-of-type) {
        margin: 0 0 22px;
      }
    }

    .store__qr {
      margin: 0 64px 0 0;

      @media (max-width: 990px) {
        margin: 0 30px 0 0;
      }
    }
  }

  &__img {
    align-self: flex-end;
    margin: -56px 0 19px 31px;

    @include xl {
      align-self: flex-start;
      margin: -38px -156px 0 -168px;
    }

    @include md {
      margin: -24px -138px 0 -126px;
    }

    @include sm {
      margin: 0 -27px 0 -31px;
    }

    @include xs {
      display: none;
    }
  }
}
