.services {
  padding: 0 0 118px;

  @include xl {
    padding: 0 0 101px;
  }

  @include md {
    padding: 0 0 80px;
  }

  @include sm {
    padding: 0 0 80px;
  }

  @include xs {
    padding: 60px 0;
  }

  @media (max-width: 358px) {
    padding: 65px 0 59px;
  }

  &--doc {
    padding: 118px 0;

    @include xl {
      padding: 100px 0 103px;
    }

    @include md {
      padding: 80px 0;
    }

    @include sm {
      padding: 80px 0;
    }
  }

  &__title {
    margin: 0 0 80px;

    @include xl {
      margin: 0 0 60px;
    }

    @include md {
      margin: 0 0 40px;
    }

    @include xs {
      margin: 0 0 30px;
    }
  }

  &__wrap {
    display: flex;
    justify-content: space-between;

    @media (max-width: 990px) {
      flex-wrap: wrap;
      margin: 0 0 -20px;
    }

    @include xs {
      margin: 0 0 -30px;
    }
  }

  &__item {
    width: calc(33.33% - 75px * 2 / 3);
    min-height: 330px;
    font-size: 15px;
    background: $color-greyLight;
    border-radius: 10px;
    padding: 20px 38px 25px 20px;

    @include xl {
      width: calc(33.33% - 20px * 2 / 3);
      padding: 20px 20px 23px 20px;
    }

    @include md {
      min-height: auto;
      font-size: 14px;
      padding: 20px 20px 26px;
    }

    @media (max-width: 990px) {
      width: calc(50% - 20px / 2);
      margin: 0 0 20px;
    }

    @include xs {
      width: 100%;
      margin: 0 0 30px;
    }
  }

  &__item-icon {
    margin: 0 0 16px;
  }

  &__item-title {
    font-weight: 700;
    font-size: 18px;
    margin: 0 0 16px;

    @include xl {
      font-size: 16px;
    }
  }

  &__item-list {

    li:not(:last-of-type) {
      margin: 0 0 16px;
    }
  }
}
