.container {
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
  position: relative;
  padding: 0 40px;

  @include xl {
    max-width: 1040px;
  }

  @include sm {
    padding: 0 20px;
  }

  @include xs {
    padding: 0 16px;
  }

  @media (max-width: 358px) {
    padding: 0 10px;
  }
}
