.faq {
  padding: 122px 0;

  @include xl {
    padding: 101px 0 97px;
  }

  @include md {
    padding: 81px 0;
  }

  @include sm {
    padding: 135px 0 90px;
  }

  @include xs {
    padding: 111px 0 69px;
  }

  @media (max-width: 358px) {
    padding: 107px 0 69px;
  }

  &__wrap {
    display: flex;

    @include sm {
      position: relative;
      flex-direction: column-reverse;
    }
  }

  &__info {
    max-width: 487px;
    width: 100%;
    flex-shrink: 0;
    padding: 0 122px 0 0;

    @include xl {
      max-width: 327px;
      padding: 0 20px 0 0;
    }

    @include md {
      max-width: 312px;
    }

    @include sm {
      max-width: 100%;
      padding: 86px 0 0;
    }

    @include xs {
      padding: 66px 0 0;
    }
  }

  &__title {
    margin: 0 0 40px;

    @include sm {
      position: absolute;
      top: -53px;
      left: 0;
    }

    @include xs {
      top: -50px;
    }

    @media (max-width: 358px) {
      top: -44px;
    }
  }

  &__subtitle {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 8px;

    @include md {
      font-size: 16px;
    }
  }

  &__info-descr {
    font-size: 15px;
    margin: 0 0 37px;

    @include md {
      font-size: 14px;
    }
  }

  &__info-soc {}

  &__info-soc-item {

    &:not(:last-of-type) {
      margin: 0 0 30px;
    }

    svg {
      fill: $color-primary;
    }
  }

  &__info-soc-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: $color-grey;
  }

  &__info-soc-icon {
    margin: 0 14px 0 0;
    flex-shrink: 0;
  }

  &__info-soc-item-wrap {}

  &__info-soc-item-name {
    font-weight: 600;
    color: $color-textBlack;
  }

  &__items {
    flex-grow: 1;
    margin: -4px 0 0;

    @include xl {
      margin: -7px 0 0;
    }

    @include md {
      margin: 0;
    }
  }

  &__item {
    border-bottom: 1px solid rgba($color: $color-primary, $alpha: .2);

    &.open {

      .faq__question {
        min-height: 60px;

        &::after {
          transform: rotate(45deg);
        }

        @include xl {
          min-height: 56px;
        }
      }

      .faq__answer {
        padding-bottom: 13px;
        opacity: 1;
        box-sizing: content-box;
      }
    }
  }

  &__question {
    width: 100%;
    min-height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    color: $color-textBlack;
    text-align: left;
    cursor: pointer;
    padding: 12px 0;
    transition: color .3s;

    &:after {
      content: '';
      width: 30px;
      height: 30px;
      background: rgba(87, 117, 255, 0.1) url(../img/svg/plus.svg) no-repeat center/15px 15px;
      border-radius: 50%;
      transition: transform .3s;
      margin: 0 0 0 70px;
      flex-shrink: 0;

      @include xl {
        margin: 0 0 0 100px;
      }

      @include md {
        margin: 0 0 0 78px;
      }

      @include xs {
        margin: 0 0 0 10px;
      }
    }

    @include xl {
      font-size: 16px;
    }

    @include md {
      min-height: 63px;
    }

    @include xs {
      font-size: 14px;
    }
  }

  &__answer {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    will-change: max-height;
    transition: all 0.3s ease-out;
    padding-right: 30px;

    @include xl {
      font-size: 14px;
    }

    @include sm {
      padding-right: 9px;
    }

    p {
      &:not(:last-of-type) {
        margin: 0 0 21px;

        @include xl {
          margin: 0 0 16px;
        }
      }

      &+ul {
        margin: 21px 0 0;
      }
    }

    li {

      &::before {
        content: '- ';
      }
    }
  }
}
