@keyframes bg-first {
  0% {
    height: 71%;
    width: 0;
    right: 0;
    top: 0;
  }

  100% {
    height: 71%;
    width: 49%;
    right: 0;
    top: 0;
  }
}

@keyframes bg-first2 {
  0% {
    height: 71%;
    width: 49%;
    right: 0;
    top: 0;
    border-radius: 0 0 0 85%;
  }


  100% {
    height: 100%;
    width: 120%;
    right: 0;
    top: 0;
    border-radius: 0;
  }
}

@keyframes bg-first-rev {
  0% {
    height: 100%;
    width: 120%;
    right: 0;
    top: 0;
    border-radius: 0 0 0 85%;
    background: $color-darkBlue;
  }


  100% {
    height: 71%;
    width: 49%;
    right: 0;
    top: 0;
    border-radius: 0 0 0 85%;
    background: $color-primary;
  }
}

@keyframes bg-first-rev2 {
  0% {
    height: 71%;
    width: 49%;
    right: 0;
    top: 0;
  }

  100% {
    height: 71%;
    width: 49%;
    right: -50%;
    top: 0;
  }
}

@keyframes opacity-box {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 1;
    animation-play-state: paused;
  }
}

@keyframes opacity-image {
  0% {
    opacity: 1;
    filter: blur(3px);
  }

  100% {
    opacity: 1;
    filter: blur(0);
  }
}

@include xl {
  @keyframes bg-first {
    0% {
      height: 67%;
      width: 0;
      right: 0;
      top: 0;
    }

    100% {
      height: 67%;
      width: 51%;
      right: 0;
      top: 0;
    }
  }

  @keyframes bg-first-rev {
    0% {
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: $color-darkBlue;
    }


    100% {
      height: 67%;
      width: 51%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: $color-primary;
    }
  }

  @keyframes bg-first-rev2 {
    0% {
      height: 67%;
      width: 51%;
      right: 0;
      top: 0;
    }

    100% {
      height: 67%;
      width: 51%;
      right: -50%;
      top: 0;
    }
  }
}

@include md {
  @keyframes bg-first {
    0% {
      height: 514px;
      width: 0;
      right: 0;
      top: 0;
    }

    100% {
      height: 514px;
      width: 510px;
      right: 0;
      top: 0;
    }
  }

  @keyframes bg-first-rev {
    0% {
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: $color-darkBlue;
    }


    100% {
      height: 514px;
      width: 510px;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: $color-primary;
    }
  }

  @keyframes bg-first-rev2 {
    0% {
      height: 514px;
      width: 510px;
      right: 0;
      top: 0;
    }

    100% {
      height: 514px;
      width: 510px;
      right: -510px;
      top: 0;
    }
  }
}

@media (max-width: 990px) {
  @keyframes bg-first-rev {
    0% {
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: $color-darkBlue;
    }


    100% {
      height: 514px;
      width: 510px;
      right: 0;
      top: 0;
      border-radius: 0 0 0 95%;
      background: rgba($color: $color-primary, $alpha: .1);
    }
  }
}

@include sm {
  @keyframes bg-first {
    0% {
      height: 426px;
      width: 0;
      right: 0;
      top: 0;
    }

    100% {
      height: 426px;
      width: 470px;
      right: 0;
      top: 0;
    }
  }

  @keyframes bg-first-rev {
    0% {
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 100%;
      background: $color-darkBlue;
    }


    100% {
      height: 426px;
      width: 470px;
      right: 0;
      top: 0;
      border-radius: 0 0 0 100%;
      background: rgba($color: $color-primary, $alpha: .1);
    }
  }

  @keyframes bg-first-rev2 {
    0% {
      height: 426px;
      width: 470px;
      right: 0;
      top: 0;
    }

    100% {
      height: 426px;
      width: 470px;
      right: -470px;
      top: 0;
    }
  }
}

@include xs {
  @keyframes bg-first {
    0% {
      height: 426px;
      width: 0;
      right: 0;
      top: 0;
    }

    100% {
      height: 426px;
      width: 100%;
      right: 0;
      top: 0;
    }
  }

  @keyframes bg-first-rev {
    0% {
      height: 100%;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 100%;
      background: $color-darkBlue;
    }


    100% {
      height: 426px;
      width: 100%;
      right: 0;
      top: 0;
      border-radius: 0 0 0 100%;
      background: rgba($color: $color-primary, $alpha: .1);
    }
  }

  @keyframes bg-first-rev2 {
    0% {
      height: 426px;
      width: 100%;
      right: 0;
      top: 0;
    }

    100% {
      height: 426px;
      width: 100%;
      right: -100%;
      top: 0;
    }
  }
}
