.hero {
  position: relative;

  @media (min-width: 1442px) and (max-height: 1024px) {
    min-height: 100vh;
  }

  @media (min-width: 1441px) and (max-height: 900px) {
    min-height: 100vh;
  }

  @media (min-width: 1024px) and (max-height: 830px) {
    min-height: 100vh;
  }

  &__main {
    padding: 160px 0 0;

    @include xl {
      padding: 169px 0 0;
    }

    @include md {
      padding: 157px 0 0;
    }

    @include sm {
      padding: 90px 0 0;
    }

    @include xs {
      padding: 100px 0 0;
    }

    @media (max-width: 358px) {
      padding: 94px 0 0;
    }

    &::before {
      content: '';
      position: absolute;
      height: 71%;
      width: 49%;
      right: 0;
      top: 0;
      background: $color-primary;
      border-radius: 0 0 0 85%;
      animation: bg-first 1s;
      z-index: -1;

      @include xl {
        width: 51%;
        height: 67%;
        border-radius: 0 0 0 95%;
      }

      @include md {
        width: 510px;
        height: 514px;
      }

      @media (max-width: 990px) {
        background: rgba($color: $color-primary, $alpha: .1);
      }

      @include sm {
        width: 470px;
        height: 426px;
        border-radius: 0 0 0 100%;
      }

      @include xs {
        width: 100%;
      }
    }
  }


  &--doctor {
    .hero__partners-item svg {
      fill: $color-white;
    }

    @include xs {
      padding-bottom: 60px;
    }

    @media (max-width: 575px) and (max-height: 650px) {
      min-height: 100vh;
    }

    @media (max-width: 358px) {
      padding-bottom: 33px;
    }

    .hero__main {
      color: $color-white;

      @include xs {
        padding: 103px 0 0;
      }

      @media (max-width: 358px) {
        padding: 91px 0 0;
      }

      &::before {
        animation: bg-first2 .5s;
        width: 120%;
        height: 100%;
        border-radius: 0;
        background: $color-darkBlue;

        @include xs {
          width: 100%;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 71%;
        width: 49%;
        right: 0;
        top: 0;
        background: rgba($color: $color-primary, $alpha: .5);
        border-radius: 0 0 0 85%;
        animation: bg-first .7s;
        z-index: -1;

        @include xl {
          width: 51%;
          height: 67%;
          border-radius: 0 0 0 95%;
        }

        @include md {
          width: 510px;
          height: 514px;
        }

        @include sm {
          width: 470px;
          height: 426px;
          border-radius: 0 0 0 100%;
        }

        @include xs {
          width: 100%;
        }
      }
    }

    .hero__content {

      @include xl {
        max-width: 600px;
      }

      @include md {
        max-width: 590px;
        padding: 0 100px 0 0;
      }

      @include sm {
        max-width: 490px;
        padding: 0 45px 0 0;
      }

      @include xs {
        max-width: 100%;
        padding: 0 0 15px;
      }
    }

    .hero__title {
      @include sm {
        margin: 0 0 30px;
        padding: 0 80px 0 0;
      }

      @media (max-width: 650px) {
        padding: 0 18px 0 0;
      }

      @include xs {
        margin: 0;
      }
    }

    .hero__list {

      @include md {
        margin: 0 0 42px;
      }

      @include sm {
        margin: 0 0 52px;
      }
    }

    .hero__img {
      @include md {
        max-width: 240px;
        width: 100%;
        margin: 6px 0 0 12px;
      }

      @media (max-width: 800px) {
        max-width: 196px;
      }

      @include sm {
        margin: -14px 0 0 2px;
      }

      @include xs {
        max-width: 243px;
        margin: 0 auto;
      }
    }

    .hero__partners {
      padding: 58px 0 56px;

      @include xl {
        padding: 22px 0 30px;
      }

      @include md {
        padding: 35px 0 29px;
      }

      @include sm {
        padding: 28px 0;
      }

      @include xs {
        padding: 26px 0 16px;
      }
    }
  }

  .hero__main .container {
    display: flex;

    @include xs {
      display: block;
    }
  }

  &__content {
    max-width: 892px;
    width: 100%;
    padding: 38px 182px 0 0;
    opacity: 1;
    animation: opacity-box 3s;
    transition-delay: 1s;

    @include xl {
      max-width: 594px;
      padding: 0 160px 0 0;
    }

    @include md {
      max-width: 520px;
      padding: 0 70px 0 0;
    }

    @include sm {
      max-width: 430px;
      padding: 15px 25px 0 0;
    }

    @include xs {
      max-width: 100%;
      padding: 0 0 22px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 1.22;
    margin: 0 0 25px;

    span {
      display: inline-block;
      font-weight: 500;
      font-size: 24px;
      margin: 14px 0 0;

      @include xl {
        font-size: 16px;
      }
    }

    @include xl {
      font-size: 32px;
      margin: 0 0 25px;
    }

    @include sm {
      margin: 0 0 24px;
    }

    @include xs {
      font-size: 26px;
      margin: 0;
    }
  }

  &__list {
    margin: 0 0 64px;

    @include xl {
      margin: 0 0 53px;
    }

    @include md {
      margin: 0 0 40px;
    }

    @include sm {
      margin: 0 0 50px;
    }

    @include xs {
      display: none;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    font-size: 18px;
    margin: 0 0 20px;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background: url(../img/svg/elem-list.svg) no-repeat center/cover;
      margin: 0 12px 0 0;
      flex-shrink: 0;
    }

    @include xl {
      font-size: 16px;
      margin: 0 0 18px;

      br {
        display: none;
      }
    }
  }

  &__store {
    @include xl {
      .store__qr {
        margin: 0 65px 0 0;
      }
    }

    @include sm {
      .store__wrap {
        display: flex;
        max-width: 100%;
        margin: 0;

        a {
          max-width: 156px;

          &:first-of-type {
            margin: 0 30px 0 0;
          }
        }
      }
    }

    @include xs {
      display: none;
    }
  }

  &__img {
    margin: 18px 0 0 17px;

    img {
      animation: opacity-image .8s;
      transition: filter .2s;
    }

    @include xl {
      max-width: 253px;
      margin: 0 0 0 -5px;
    }

    @include md {
      max-width: 243px;
      width: 100%;
      margin: 9px 0 0 64px;
    }


    @media (max-width: 800px) {
      max-width: 193px;
    }

    @include sm {
      margin: -13px 0 0 42px;
    }

    @include xs {
      max-width: 286px;
      margin: 0 auto;
    }
  }

  &__partners {
    padding: 58px 0 115px;

    @include xl {
      padding: 30px 0 92px;
    }

    @include md {
      padding: 39px 0 67px;
    }

    @include sm {
      padding: 27px 0 67px;
    }

    @include xs {
      padding: 21px 0 20px;
    }

    @media (max-width: 358px) {
      padding: 14px 0;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1276px;

      @include xl {
        max-width: 850px;
      }

      @include sm {
        max-width: 100%;
        padding: 0 70px;
      }

      @media (max-width: 650px) {
        padding: 0 20px;
      }

      @include xs {
        padding: 0 16px;
      }

      @media (max-width: 358px) {
        padding: 0 10px;
      }
    }
  }

  &__partners-item {
    svg {
      @include xl {
        max-width: 152px;
        width: 100%;
        height: auto;
      }
    }

    &:not(:last-of-type) {
      margin: 0 150px 0 0;

      @include xl {
        margin: 0 100px 0 0;
      }

      @include md {
        margin: 0 98px 0 0;
      }

      @include sm {
        margin: 0 80px 0 0;
      }

      @media (max-width: 650px) {
        margin: 0 26px 0 0;
      }

      @media (max-width: 358px) {
        margin: 0 17px 0 0;
      }
    }

    svg {
      fill: $color-secondary;
    }
  }

  &__mobile-store {
    display: none;

    @include xs {
      display: block;
    }

    .container {
      display: flex;
      justify-content: center;
    }

    a {
      max-width: 156px;

      &:first-of-type {
        margin: 0 20px 0 0;
      }
    }
  }
  &__mobile-store-case {
    padding: 0 0 20px;
  }
}
