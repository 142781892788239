.footer {
  padding: 40px 0;

  &__wrap {
    display: flex;
    justify-content: space-between;
    max-width: 1210px;
    width: 100%;

    @include xl {
      max-width: 924px;
      justify-content: flex-start;
    }

    @include sm {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__info {
    max-width: 466px;
    width: 100%;
    margin: 0 60px 0 0;

    @include xl {
      max-width: 328px;
    }

    @include md {
      margin: 0 40px 0 0;
    }

    @include sm {
      max-width: 300px;
      order: 1;
      margin: 0;
    }
  }

  &__logo {
    fill: $color-primary;
    margin: 0 0 20px;

    @include sm {
      display: none;
    }
  }

  &__info-article {
    font-size: 14px;
    color: $color-secondary;
    margin: 0 0 15px;

    @include xl {
      font-size: 12px;
    }

    @include sm {
      font-size: 11px;
      margin: 0 0 30px;

      p:not(:last-of-type) {
        margin: 0 0 10px;
      }
    }
  }

  &__pay {
    display: flex;
    align-items: center;

    @include sm {
      justify-content: center;
    }
  }

  &__pay-item {
    &:not(:last-of-type) {
      margin: 0 50px 0 0;
    }
  }

  &__contacts {
    max-width: 345px;
    width: 100%;
    margin: 11px 60px 0 0;

    @include xl {
      max-width: 228px;
      font-size: 14px;
      margin: 11px 83px 0 0;
    }

    @include md {
      margin: 11px 62px 0 0;
    }

    @include sm {
      margin: 0 0 20px;
    }

    br {
      display: none;

      @include xl {
        display: inline-block;
      }
    }

    .footer__sk_logo {
      display: none;
      margin: 40px auto 0;

      @include sm {
        display: block;
      }
    }
  }

  &__contacts-title {
    font-weight: 600;
    font-size: 18px;
    margin: 0 0 18px;

    @include xl {
      font-size: 16px;
    }

    @include sm {
      margin: 0 0 21px;
    }
  }

  &__contacts-tel {
    margin: 0 0 18px;

    @include sm {
      margin: 0 0 14px;
    }
  }

  &__nav {
    margin: 11px 0 0;

    @include sm {
      margin: 0 0 25px;
    }
  }

  &__nav-btn {
    display: block;
    font-weight: 600;
    font-size: 18px;
    color: $color-textBlack;
    margin: 0 0 31px;
    transition: color .3s;
    text-align: left;

    &:hover {
      color: $color-grey;
    }

    &:active {
      color: $color-primary;
    }

    @include xl {
      font-size: 16px;
    }

    @include sm {
      display: none;
    }
  }

  &__nav-soc {
    display: flex;
    padding-top: 34px;

    @include sm {
      justify-content: center;
    }
  }

  &__nav-soc-item {
    &:not(:last-of-type) {
      margin: 0 34px 0 0;
    }

    svg {
      fill: $color-primary;
    }
  }

  &__sk_logo {
    height: 39px;
  }
}
