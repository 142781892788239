.work {
  color: $color-white;
  background: $color-darkBlue;
  padding: 122px 0;

  @include xl {
    padding: 101px 0 87px;
  }

  @include md {
    padding: 81px 0 77px;
  }

  @include sm {
    padding: 80px 0;
  }

  @include xs {
    padding: 62px 0 57px;
  }

  &--doctor .work__item:first-of-type .work__item-img {
    @include md {
      max-width: 300px;
    }

    @include sm {
      max-width: 267px;
    }

    @include xs {
      max-width: 100%;
    }
  }

  &__title {
    margin: 0 0 78px;

    @include md {
      margin: 0 0 59px;
    }

    @include sm {
      margin: 0 0 60px;
    }

    @include xs {
      margin: 0 0 30px;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-of-type) {
      margin: 0 0 82px;

      @include md {
        margin: 0 0 62px;
      }

      @include xs {
        margin: 0 0 25px;
      }

      @media (max-width: 358px) {
        margin: 0 0 36px;
      }
    }

    &:nth-of-type(2n) {
      flex-direction: row-reverse;
      max-width: 1183px;

      .work__item-descr {
        padding: 0 114px 0 0;

        @include xl {
          padding: 0 60px 0 0;
          margin: 0 auto 0 0;
        }

        @include md {
          padding: 0 20px 0 0;
        }

        @include xs {
          padding: 0 0 30px;
        }
      }

      @include xl {
        max-width: 820px;
      }

      @include xs {
        flex-direction: column-reverse;
      }
    }

    @include xs {
      align-items: flex-start;
      flex-direction: column-reverse;
    }
  }

  &__item-img {
    @include xl {
      max-width: 345px;
      width: 100%;
    }

    @include md {
      max-width: 370px;
    }

    @include sm {
      max-width: 330px;
    }

    @include xs {
      max-width: 100%;

      img {
        max-height: 320px;
        object-fit: contain;
      }
    }
  }

  &__item-icon {}

  &__item-descr {
    max-width: 712px;
    width: 100%;
    padding: 0 0 0 114px;
    margin: 0 auto;

    @include xl {
      max-width: 460px;
      padding: 0 0 0 50px;
    }

    @include md {
      max-width: 429px;
      padding: 0 0 0 20px;
    }

    @include sm {
      max-width: 375px;
      padding: 0 0 0 33px;
    }

    @include xs {
      padding: 0 0 30px;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 26px;
    margin: 0 0 40px;

    @include xl {
      font-size: 24px;
    }

    @include md {
      font-size: 22px;
      margin: 0 0 20px;
    }

    @include sm {
      font-size: 20px;
    }

    @include xs {
      font-size: 18px;
      margin: 0 0 20px;
    }
  }

  &__item-article {
    font-size: 18px;
    line-height: 1.23;

    p:not(:last-of-type) {
      margin: 0 0 26px;

      @include sm {
        margin: 0 0 16px;
      }

      @include xs {
        margin: 0 0 14px;
      }
    }

    @include xl {
      font-size: 16px;
    }

    @include md {
      font-size: 14px;
    }

    @include xs {
      font-size: 14px;
    }

    a {
      display: inline;
      text-decoration: underline;
    }
  }
}
