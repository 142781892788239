.main {
  &__content {
    display: none;

    &.active {
      display: block;
    }
  }
}

.wow {
  visibility: hidden;
}

.activeAnimate {
  .hero__main {
    &::before {
      animation: bg-first-rev .5s;
      width: 49%;
      height: 71%;
      border-radius: 0 0 0 85%;
      background: $color-primary;
      transition: all .9s;

      @include xl {
        width: 51%;
        height: 67%;
        border-radius: 0 0 0 95%;
      }

      @include md {
        width: 510px;
        height: 514px;
      }

      @media (max-width: 990px) {
        background: rgba($color: $color-primary, $alpha: .1);
      }

      @include sm {
        width: 470px;
        height: 426px;
        border-radius: 0 0 0 100%;
      }

      @include xs {
        width: 100%;
      }
    }

    &::after {
      content: '';
      position: absolute;
      height: 100%;
      width: 0;
      right: 0;
      top: 0;
      background: $color-primary;
      border-radius: 0 0 0 85%;
      animation: bg-first-rev2 .7s;
      z-index: -1;

      @include xl {
        border-radius: 0 0 0 95%;
      }

      @include sm {
        border-radius: 0 0 0 100%;
      }
    }
  }
}

.store {
  display: flex;
  align-items: center;

  &__qr {
    width: 155px;
    flex-shrink: 0;
    font-size: 12px;
    text-align: center;
    margin: 0 68px 0 0;

    @include sm {
      display: none;
    }
  }

  &__qr-info {
    margin: 0 0 10px;
  }

  &__wrap {
    max-width: 156px;
    width: 100%;
    flex-shrink: 0;
    margin: 44px 0 0 0;

    a {
      display: block;
      width: 100%;

      &:not(:last-of-type) {
        margin: 0 0 20px;
      }
    }
  }
}

.title {
  font-weight: 700;
  font-size: 34px;

  @include xl {
    font-size: 30px;
  }

  @include md {
    font-size: 26px;
  }

  @include sm {
    font-size: 24px;
  }

  @include xs {
    font-size: 22px;
  }
}

#doctor .animate__animated,
#documents .animate__animated {
  visibility: hidden;
}
