.digital {
  padding: 160px 0 100px;
  font-size: 20px;

  @include xl {
    font-size: 18px;
    padding: 120px 0 60px;
  }
  @include sm {
    padding: 100px 0 40px;
    font-size: 18px;
  }
  @include xs {
    padding: 90px 0 20px;
    font-size: 16px;
  }
  @media (max-width: 568px) {
    padding: 90px 0 20px;
    font-size: 14px;
  }

  p {
    margin: 0 0 30px;
    line-height: 1.3em;

    @include xl {
      margin: 0 0 30px;
    }
    @include sm {
      margin: 0 0 20px;
    }
    @include xs {
      margin: 0 0 15px;
    }
    @media (max-width: 568px) {
      margin: 0 0 10px;
    }
  }

  ul {
    margin: 0 0 40px 21px;
    list-style-type: disc;
    list-style-position: outside;

    @include xl {
      margin: 0 0 40px 21px;
    }
    @include sm {
      margin: 0 0 30px 21px;
    }
    @include xs {
      margin: 0 0 15px 21px;
    }
    @media (max-width: 568px) {
      margin: 0 0 10px 21px;
    }
  }

  li ul {
    margin: 10px 21px 20px;
    list-style-type: ' — ';
    list-style-position: outside;
  }

  ul li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
    display: initial;
  }

  h1 {
    font-size: 34px;
    padding: 0 0 40px;

    @include xl {
      font-size: 30px;
      padding: 0 0 30px;
    }
    @include sm {
      font-size: 26px;
      padding: 0 0 20px;
    }
    @include xs {
      font-size: 26px;
      padding: 0 0 20px;
    }
    @media (max-width: 568px) {
      font-size: 24px;
      padding: 0 0 20px;
    }
  }

  h2 {
    font-size: 30px;
    padding: 0 0 40px;
    color: $color-primary;

    @include xl {
      font-size: 26px;
      padding: 0 0 30px;
    }
    @include sm {
      font-size: 26px;
      padding: 0 0 20px;
    }
    @include xs {
      font-size: 22px;
      padding: 0 0 20px;
    }
    @media (max-width: 568px) {
      font-size: 20px;
      padding: 0 0 20px;
    }
  }
}
