.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  color: $color-white;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity .3s, visibility .3s;
  background: $color-primary;
  z-index: 5;
  padding: 8px 0 70px;
  overflow: auto;
  display: none;

  &.active {
    pointer-events: all;
    opacity: 1;
    visibility: visible;
  }

  @media(max-width: 990px) {
    display: block;

    &--preload {
      display: none;
    }
  }

  .container {
    display: block;
  }

  &__logo {
    text-align: left;
    margin: 0 auto 50px 0;

    svg {
      fill: $color-white;
    }

    @include xs {
      margin: 0 auto 33px 0;
    }
  }

  &__nav {
    text-align: center;
  }

  &__list {
    margin: 0 0 40px;

    li {
      &:not(:last-of-type) {
        margin: 0 0 21px;
      }
    }
  }

  &__button {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    color: $color-white;

    &::before {
      height: 2px;
      content: '';
      left: 50%;
      right: 50%;
      background: $color-white;
      position: absolute;
      bottom: -3px;
      transition: all .4s;
    }

    &.active {
      &::before {
        left: 0;
        right: 0;
      }
    }
  }

  &__tel {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 14px;
  }

  &__info {
    font-size: 14px;
    margin: 0 0 30px;
  }

  &__soc {
    display: flex;
    justify-content: center;
  }

  &__soc-item {
    &:not(:last-of-type) {
      margin: 0 34px 0 0;
    }

    svg {
      fill: $color-white;
    }
  }

  &__sk_logo {
    height: 39px;
    margin: 28px auto 0;
  }
}

.burger {
  display: none;

  @media(max-width: 990px) {
    position: fixed;
    top: 11px;
    right: 20px;
    z-index: 6;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    transition: background .3s;

    span {
      background: $color-primary;
      width: 20px;
      height: 2px;
      border-radius: 10px;
      transition: 0.3s;
    }

    span:nth-child(2) {
      margin: 4px 0;
    }

    &.active {
      span:nth-child(1) {
        position: absolute;
        top: 19px;
        transform: rotate(45deg);
        background: $color-white;
      }

      span:nth-child(2) {
        transform: scale(0);
      }



      span:nth-child(3) {
        transform: rotate(-45deg);
        position: absolute;
        bottom: 19px;
        background: $color-white;
      }
    }
  }

  @include xs {
    right: 16px;
  }

  @media (max-width: 358px) {
    right: 10px;
  }

  &.fixed {
    background: $color-white;

    span {
      background: $color-primary;
    }
  }
}
