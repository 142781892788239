@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Montserrat";

  font-display: swap;
  src: url("../fonts/montserrat/Montserrat-Regular.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src: url("../fonts/montserrat/Montserrat-Medium.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src: url("../fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Montserrat";

  font-display: swap;
  src: url("../fonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("../fonts/montserrat/Montserrat-Bold.woff") format("woff");
}
