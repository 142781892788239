.documents {
  padding: 259px 0 127px;

  @include xl {
    padding: 220px 0 90px;
  }

  @include sm {
    padding: 124px 0 69px;
  }

  @include xs {
    padding: 113px 0 50px;
  }

  @media (max-width: 568px) {
    padding: 109px 0 50px;
  }

  &__title {
    margin: 0 0 80px;

    @include xl {
      margin: 0 0 58px;
    }

    @include md {
      margin: 0 0 38px;
    }

    @include sm {
      margin: 0 0 40px;
    }

    @include xs {
      margin: 0 0 30px;
    }
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 12px;

    @include xl {
      margin: 0 0 31px;
    }

    @include md {
      margin: 0 0 23px;
    }

    @media (max-width: 990px) {
      justify-content: space-between;
    }

    @include sm {
      margin: 0 0 21px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    width: calc(25% - 20px * 3 / 4);
    min-height: 124px;
    background: $color-greyLight;
    border-radius: 20px;
    padding: 20px 25px 20px 20px;
    margin: 0 20px 40px 0;

    &:nth-of-type(4n) {
      margin-right: 0;

      @include xl {
        margin-right: 20px;
      }

      @media (max-width: 990px) {
        margin-right: 0;
      }
    }

    @include xl {
      width: calc(33.33% - 20px * 2 / 3);
      padding: 20px;
      margin: 0 20px 20px 0;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    @include md {
      margin: 0 20px 28px 0;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 990px) {
      width: calc(50% - 20px / 2);
      min-height: 100px;
      margin: 0 0 28px;
    }

    @include sm {
      width: 100%;
      min-height: 74px;
      margin: 0 0 20px;
    }

    @include xs {
      font-size: 14px;
      margin: 0 0 20px;
    }

    @media (max-width: 358px) {
      padding: 10px 29px 10px 20px;
      margin: 0 0 20px;
    }
  }

  &__item-icon {
    flex-shrink: 0;
    margin: 0 16px 0 0;

    @include sm {
      margin: 0 20px 0 0;
    }

    @include xs {
      margin: 0 16px 0 0;
    }
  }

  &__pricelist {
    font-weight: 600;
    color: $color-primary;
    transition: color .3s;

    &:active {
      color: $color-darkBlue;
    }

    @include xs {
      display: block;
      text-align: center;
    }
  }
}
