header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background: transparent;
  transition: all .2s;

  @include md {
    position: absolute;
  }

  &.header--preload {
    display: none;
  }

  &.fixed {
    background: $color-white;
    transition: background .2s;

    @include md {
      background: transparent;
    }

    .header__sk_logo {
      display: none;
    }

    .header__buttons-download {
      width: auto;
      padding: 0 55px;
      margin: 0 0 0 90px;
      visibility: visible;
      pointer-events: all;
      opacity: 1;
    }

    .header__buttons-enter {
      color: $color-primary;

      svg {
        fill: $color-primary;
      }

      &:hover {
        color: $color-darkBlue;

        svg {
          fill: $color-darkBlue;
        }
      }

      &:active {
        color: $color-primary;

        svg {
          fill: $color-primary;
        }
      }
    }
  }

  &.light:not(.fixed) {
    .header__logo svg {
      fill: $color-white;
    }

    .header__nav-btn {
      color: $color-white;

      &::before {
        background: $color-white;
      }

      &.active {
        &::before {
          background: $color-white;
        }
      }

      &:active {
        color: $color-white;

        &::before {
          background: $color-white;
        }
      }

    }

    .burger {
      &.fixed {
        span {
          background: $color-primary;
        }
      }

      span {
        background: $color-white;
      }
    }
  }
}

.header {
  padding: 28px 0;
  visibility: visible !important;

  @media(max-width: 990px) {
    padding: 8px 0;
  }

  .container {
    display: flex;
    align-items: center;
  }

  &__logo {
    flex-shrink: 0;

    &.active {
      pointer-events: none;
    }

    svg {
      fill: $color-primary;
      transition: fill .3s;

      @media(max-width: 990px) {
        width: 42px;
        height: 46px;
      }

      @include xs {
        width: 50px;
        height: 54px;
      }
    }

  }

  &__nav {
    display: flex;
    margin: 0 20px 0 185px;

    @include xl {
      margin: 0 20px 0 105px;
    }

    @include md {
      margin: 0 20px 0 99px;
    }

    @media(max-width: 990px) {
      display: none;
    }
  }

  &__nav-item {

    &:first-of-type {
      margin: 0 113px 0 0;

      @include xl {
        margin: 0 66px 0 0;
      }

      @include md {
        margin: 0 58px 0 0;
      }
    }
  }

  &__nav-btn {
    position: relative;
    font-size: 18px;
    color: $color-primary;
    transition: color .3s;

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: -3px;
      height: 2px;
      background: $color-primary;
      transition: all .4s;
    }

    &.active {
      font-weight: 700;
      pointer-events: none;

      &::before {
        left: 0;
        right: 0;
      }
    }

    &:hover:not(.active) {
      color: $color-darkBlue;

      &::before {
        left: 0;
        right: 0;
        background: $color-darkBlue;
      }
    }

    &:active {
      font-weight: 700;
      color: $color-primary;

      &::before {
        background: $color-primary;
      }
    }

    @include xl {
      font-size: 16px;
    }
  }

  &__buttons {
    display: flex;
    margin: 0 15px 0 auto;

    @media (max-width: 990px) {
      display: none;
    }
  }

  &__buttons-enter {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $color-white;
    pointer-events: all;
    transition: color .3s;

    &.hidden {
      color: transparent;
      pointer-events: none;

      svg {
        fill: transparent;
      }
    }

    svg {
      fill: $color-white;
      margin: 0 14px 0 0;
      flex-shrink: 0;
      transition: fill .3s;
    }

    &:hover {
      color: #F2F5F8;

      svg {
        fill: #F2F5F8;
      }
    }

    &:active {
      font-weight: 600;
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }

  &__buttons-download {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0;
    min-height: 54px;
    font-weight: 600;
    color: $color-white;
    background: $color-primary;
    border-radius: 60px;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    transition: background .3s, opacity .3s, visibility .3s, width .4s, padding .5s, margin .6s;
    position: relative;

    &:hover {
      background: #506CE9;
    }

    &:active {
      background: $color-darkBlue;
    }

    @include md {
      display: none;
    }
  }

  &__sk_logo {
    height: 39px;
    margin-left: 57px;
    margin-top: 3px;

    @include md {
      margin-top: -10px;
    }

    @include xxl {
      margin-top: 16px;
    }
  }
}
